import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: 'https://pospebiz.com/api/',
  timeout: 20000,
});

axiosInstance.interceptors.request.use(config => {
  return config;
}, error => {
  return Promise.reject(error);
});

axiosInstance.interceptors.response.use(response => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    throw new Error(`Request failed with status ${response.status}`);
  }
}, error => {
  return Promise.reject(error);
});

export default axiosInstance;
