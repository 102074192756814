class AuthService {
    static login(token) {
        localStorage.setItem('sessionToken', token);
    }

    static logout() {
        localStorage.removeItem('sessionToken');
    }

    static getSessionToken() {
        return localStorage.getItem('sessionToken');
    }

    static isAuthenticated() {
        return !!localStorage.getItem('sessionToken');
    }
}

export default AuthService;
