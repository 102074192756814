import React, {lazy} from 'react';
import {Navigate} from 'react-router-dom';
import Loadable from '../layouts/full/shared/loadable/Loadable';
import AuthService from '../views/authentication/AuthService';

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('../layouts/full/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/blank/BlankLayout')));

/* ****Pages***** */
const Dashboard = Loadable(lazy(() => import('../views/dashboard/Dashboard')))
const SamplePage = Loadable(lazy(() => import('../views/sample-page/SamplePage')))
const Icons = Loadable(lazy(() => import('../views/icons/Icons')))
const TypographyPage = Loadable(lazy(() => import('../views/utilities/TypographyPage')))
const Shadow = Loadable(lazy(() => import('../views/utilities/Shadow')))
const Error = Loadable(lazy(() => import('../views/authentication/Error')));
const Register = Loadable(lazy(() => import('../views/authentication/Register')));
const Login = Loadable(lazy(() => import('../views/authentication/Login')));
const Aeps = Loadable(lazy(() => import('../views/dashboard/aeps/Aeps')));

const ProtectedRoute = ({element, path}) => {
    return AuthService.isAuthenticated() ? (
        element
    ) : (
        <Navigate to="/auth/login" replace state={{from: path}}/>
    );
};
const FreeRoute = ({ element }) => {
    return AuthService.isAuthenticated() ? (
        <Navigate to="/dashboard" replace />
    ) : (
        element
    );
};

const Router = [
    {
        path: '/',
        element: <FullLayout/>,
        children: [
            {path: '/', element: <Navigate to="/dashboard"/>},
            {path: '/dashboard', exact: true, element: <ProtectedRoute element={<Dashboard/>} path="/dashboard"/>},
            {path: '/sample-page', exact: true, element: <ProtectedRoute element={<SamplePage/>} path="/sample-page"/>},
            {path: '/dashboard/Aeps', exact: true, element: <ProtectedRoute element={<Aeps/>} path="/dashboard/Aeps"/>},
            {path: '/icons', exact: true, element: <ProtectedRoute element={<Icons/>} path="/icons"/>},
            {
                path: '/ui/typography',
                exact: true,
                element: <ProtectedRoute element={<TypographyPage/>} path="/ui/typography"/>
            },
            {path: '/ui/shadow', exact: true, element: <ProtectedRoute element={<Shadow/>} path="/ui/shadow"/>},
            {path: '*', element: <Navigate to="/auth/404"/>},
        ],
    },
    {
        path: '/auth',
        element: <BlankLayout />,
        children: [
            { path: '404', element: <Error /> },
            { path: '/auth/register', element: <Register /> },
            { path: '/auth/login', element: <FreeRoute element={<Login />} /> }, // Use FreeRoute here
            { path: '*', element: <Navigate to="/auth/404" /> },
        ],
    },

];

export default Router;
